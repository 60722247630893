
















































































































import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import ErrorComponent from './ErrorComponent.vue';
import PayeeComponent from './PayeeComponent.vue';
import ValidationSummaryComponent from './ValidationSummaryComponent.vue';
import ImpactCategoriesComponent from './ImpactCategoriesComponent.vue';
import DialogComponent from './DialogComponent.vue';
import SpinnerComponent from './SpinnerComponent.vue';
import ProjectHelper from './ProjectHelper';
import { rules } from '../lib/validation';
import * as _ from 'lodash';

import { setProjectVendor, getProject, getVendors } from '../store/rest.service';
import { AffiliatedVendorsMeta, Project, Vendor } from '../store/models';
import { EmptyAffiliatedVendorsMeta, EmptyProject, EmptyVendor } from '../store/models-empty';
import { handleError } from '@/lib/shared';
import PayeeHelper, { VendorCategory } from './PayeeHelper';

@Component({
  components: {
    ErrorComponent,
    ValidationSummaryComponent,
    SpinnerComponent,
    DialogComponent,
    PayeeComponent,
    ImpactCategoriesComponent,
  },
})
export default class ProjectPayeeComponent extends BaseComponent {

  @Prop() private projectId: any;
  @Prop() private vendorId: any;
  @Prop() private project: any;

  private helper: ProjectHelper = new ProjectHelper();
  private helperLoading: boolean = true;

  private payeeHelper: PayeeHelper = new PayeeHelper(VendorCategory.None);
  private payeeHelperLoading: boolean = true;

  private projectLoading: boolean = false;
  private projectVendorSaving: boolean = false;
  private arrErrors: Error[] = [];

  private editedProjectItem: Project = _.cloneDeep(EmptyProject);
  private editedPayeeItem: Vendor = _.cloneDeep(EmptyVendor);
  private payeeLoading: boolean = false;
  private vendorAggregateLoading: boolean = false;
  private editedAffiliatedVendor: AffiliatedVendorsMeta = _.cloneDeep(EmptyAffiliatedVendorsMeta);
  private projectVendorLoading: boolean = false;
  private bEditPayee: boolean = false;
  private bAddPayee: boolean = false;
  private editedVendorId: string = '';
  private updateValidation: number = 0;
  private updateVendor: number = 0;

  protected mounted() {
    this.payeeHelper.getDiversityReferenceData();
    this.payeeHelper.getIndigenousCommunities();
    this.helper.getVendors(this.currentOrg, true);
    this.helper.getIndigenousCommunities();
    this.getProject(this.projectId);
    if (!this.vendorId) {
      this.initProjectVendor();
    }
  }

  private addPayee() {
    this.bAddPayee = true;
    this.editedVendorId = '';
  }

  private editPayee() {
    this.bEditPayee = true;
    this.editedVendorId = this.editedAffiliatedVendor.vendorId;
  }

  private closePayee() {
    this.bEditPayee = false;
    this.bAddPayee = false;
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }

  private getAffiliatedVendor() {
    if (this.editedProjectItem.affiliatedVendors) {
      const affiliatedVendor = this.editedProjectItem.affiliatedVendors.find((x) => x.vendorId === this.vendorId);
      if (affiliatedVendor !== undefined) {
        this.editedAffiliatedVendor = affiliatedVendor;
        this.getVendor();
      } else {
        this.initProjectVendor();
      }
    } else {
      this.initProjectVendor();
    }
  }

  private getVendor() {
    this.payeeLoading = true;
    getVendors(this.mapSearchParams({ sortBy: 'default', descending: false, page: 1, rowsPerPage: 1 }, '', []), this.editedAffiliatedVendor.vendorId)
    .then((response) => {
      if (response.searchResults.results[0]) {
        this.editedPayeeItem = response.searchResults.results[0] as Vendor;
      }
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.updateVendor++;
      this.payeeLoading = false;
    });
  }

  @Watch('helper.isLoading')
  private onHelperChanged(val: any, oldVal: any) {
    this.helperLoading = this.helper.isLoading;
  }

  @Watch('payeeHelper.isLoading')
  private onPayeeHelperChanged(val: any, oldVal: any) {
    this.payeeHelperLoading = this.payeeHelper.isLoading;
  }

  private isLoading(): boolean {
    return this.projectVendorLoading
    || this.helperLoading
    || this.payeeHelperLoading
    || this.payeeLoading;
  }

  private initProjectVendor() {
    this.editedAffiliatedVendor = _.cloneDeep(EmptyAffiliatedVendorsMeta);
    this.editedAffiliatedVendor.vendorId = this.vendorId;
    this.getVendor();
  }

  private close() {
    this.$emit('close');
  }

  private saveVendor(id: string) {
    this.getVendor();
    if (id !== '') {
      this.helper.getVendors(this.currentOrg, true);
      this.editedAffiliatedVendor.vendorId = id;
    }
  }

  private save() {
    if (!this.projectVendorSaving) {
      if ((this.$refs.form1 as any).validate()) {
        this.updateValidation++;
        this.setProjectVendor();
      } else {
        this.updateValidation++;
      }
    }
  }

  private getProject(id: string) {
    this.projectLoading = true;
    getProject(id, true).then((response) => {
      this.editedProjectItem = response;
      this.getAffiliatedVendor();
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.projectLoading = false;
    });
  }

  private setProjectVendor() {
    this.projectVendorSaving = true;
    setProjectVendor(this.projectId, this.editedAffiliatedVendor).then((response) => {
      this.close();
      this.$emit('save', response);
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.projectVendorSaving = false;
    });
  }
}
