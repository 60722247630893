











































import { Component, Watch } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ProjectPayeesTableComponent from '../components/ProjectPayeesTableComponent.vue';
import ProjectPayeeComponent from '../components/ProjectPayeeComponent.vue';
import ExpenseComponent from '../components/ExpenseComponent.vue';
import ErrorComponent from '../components/ErrorComponent.vue';
import DialogComponent from '../components/DialogComponent.vue';
import { handleError } from '../lib/shared';
import * as _ from 'lodash';

@Component({
    components: {
      ErrorComponent,
      DialogComponent,
      ProjectPayeesTableComponent,
      ExpenseComponent,
      ProjectPayeeComponent,
    },
  })
export default class ProjectPayees extends BaseComponent {

  public arrErrors: Error[] = [];
  protected baseRoute: string = '/projects';

  private projectId: string = '';
  private bAddVendor: boolean = false;
  private bAddExpense: boolean = false;
  private editedVendorId: string = '';
  private mountPage: boolean = false;

  private expenseLoading: boolean = false;
  private updateExpense: number = 0;

  private vendorLoading: boolean = false;
  private updateVendor: number = 0;
  private updateVendors: number = 0;

  private updateProjectVendor: number = 0;

  protected mounted() {
    this.mount();
  }

  protected newVendorItem() {
    this.editedVendorId = '';
    this.bAddVendor = true;
  }

  protected editVendorItem(id: string) {
    this.editedVendorId = id;
    this.bAddVendor = true;
  }

  protected newExpenseItem() {
    this.bAddExpense = true;
  }

  private mount() {
    if (this.$route.path.endsWith('new-payee')) {
      this.newVendorItem();
    } else if (this.$route.path.endsWith('new-expense')) {
      this.newExpenseItem();
    } else if (this.$route.params.payeeId) {
      this.editVendorItem(this.$route.params.payeeId);
    } else {
      this.mountPage = true;
    }
    this.projectId = this.$route.params.id;
    this.baseRoute = '/projects/id/' + this.projectId + '/payees';
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }

  private refreshVendor() {
    this.updateVendor++;
  }

  private refreshVendors() {
    this.updateVendors++;
    this.mount();
  }

  private closeEditVendor() {
    this.bAddVendor = false;
    this.changeRoute(this.baseRoute);
  }

  private saveEditVendor() {
    this.refreshVendor();
    this.refreshVendors();
  }

  private closeExpense() {
    this.bAddExpense = false;
    this.changeRoute(this.baseRoute);
  }

  private saveExpense() {
    this.refreshVendor();
    this.refreshVendors();
  }

  private editVendor(id) {
    this.editedVendorId = id;
    this.bAddVendor = true;
  }

  private navPayee(id) {
    this.$router.push({ path: '/projects/id/' + this.$route.params.id + '/payees/id/' + id});
  }
}
