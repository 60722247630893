





























































































import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import { getProjectPayees } from '../store/rest.service';
import SpinnerComponent from './SpinnerComponent.vue';
import ExpensesTableComponent from './ExpensesTableComponent.vue';
import { getIconUrl } from '../lib/images';
import { AffiliatedVendorsMeta, AggSearchCriteria, GeneralSearchCriteria, PayeeSearchCriteria, Project, ReferenceData, ResourceSearchResult, SortColumnMapping } from '../store/models';
import * as _ from 'lodash';
import PayeeHelper from './PayeeHelper';
import { VendorCategory } from './PayeeHelper';
import ProjectHelper from './ProjectHelper';
import FiltersComponent from './FiltersComponent.vue';
import MobileBottomNavComponent from "../components/MobileBottomNavComponent.vue";
import { EmptyAffiliatedVendorsMeta, EmptyAggSearchCriteria, EmptyGeneralSearchCriteria, EmptyPayeeSearchCriteria } from '@/store/models-empty';
import { setPayeeSearchFacets } from '@/lib/facets';

@Component({
  components: {
    SpinnerComponent,
    FiltersComponent,
    ExpensesTableComponent,
    MobileBottomNavComponent,
  },
})
export default class ProjectPayeesTableComponent extends BaseComponent {

  @Prop() private projectId: any;

  private helper: PayeeHelper = new PayeeHelper(VendorCategory.None);
  private helperLoading: boolean = true;
  private projectHelper: ProjectHelper = new ProjectHelper();
  private projectHelperLoading: boolean = true;
  private searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
  private payeeSearchCriteria: PayeeSearchCriteria = _.cloneDeep(EmptyPayeeSearchCriteria);
  private aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
  private debounceSearch = _.debounce(this.getProjectPayees, 500);

  private arrErrors: Error[] = [];

  private bInitialLoad: boolean = true;
  private bInitialLoadWatch: boolean = true;
  private pagination: any = { sortBy: 'common.displayName', descending: false, page: 1, rowsPerPage: 10 };
  private rowsPerPage: number[] = [10, 25, 100];
  private searchTerms: string = '';
  private expand: boolean = false;

  private payees: ResourceSearchResult|null = null;
  private payeesLoading: boolean = false;

  private payeesHeaders = [
    { text: 'Vendor/Supplier', align: 'left', sortable: true, value: 'common.displayName', class: 'grid-header' },
    { text: 'Manage', align: 'left', class: 'grid-header', sortable: false },
    { text: 'Type', align: 'left', sortable: true, value: 'vendor.defaultExpenseClass', class: 'grid-header' },
    { text: 'Spend', align: 'left', sortable: true, value: 'totals.expenses.expenseTotals.totalProjectSpend', class: 'grid-header' },
    /*
    { text: 'Invited Vendor', align: 'left', sortable: false, class: 'grid-header' },
    */
    { text: 'Local', align: 'center', sortable: false, class: 'grid-header' },
    { text: 'Community Partner', align: 'left', sortable: false, class: 'grid-header' },
    { text: 'Owned by Indigenous Community', align: 'left', sortable: false, class: 'grid-header' },
    { text: 'Supply Chain Diversity', align: 'left', sortable: false, class: 'grid-header' },
  ];

  private sortMappings: SortColumnMapping[] = [
    {
      field: 'common.displayName',
      sortColumn: 'default',
    },
  ];

  protected mounted() {
    this.bInitialLoad = true;
    this.setSearchCriteria();
    if (this.projectId !== '') {
      this.getProjectPayees();
      this.projectHelper.getProject(this.projectId);
      this.helper.getDefaultExpenseClasses();
      this.helper.getDiversityReferenceData();
      this.helper.getIndigenousCommunities();
    }
  }

  private setSearchCriteria() {
    this.searchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    this.aggSearchCriteria.hasPayee = 'id';
  }

  private getEditIcon(): string {
    return getIconUrl('icon_edit.png');
  }

  private updateFacets(facets: any) {
    this.getProjectPayees();
  }

  private getTickIcon(): string {
    return getIconUrl('icon_tick.png');
  }

  private getInviteStatusClass(status: string): string {
    return "text-xs-left" + (status === 'Invite' ? " color-green" : "" );
  }

  private getPayeeAffiliation(payeeId: string): AffiliatedVendorsMeta {
    const emptyPayeeAffiliation = _.cloneDeep(EmptyAffiliatedVendorsMeta);
    if ((this.projectHelper.project as Project).affiliatedVendors) {
      const payeeAffiliation = ((this.projectHelper.project as Project).affiliatedVendors as AffiliatedVendorsMeta[]).find((x) => x.vendorId === payeeId);
      return payeeAffiliation !== undefined ? payeeAffiliation : emptyPayeeAffiliation;
    } else {
      return emptyPayeeAffiliation;
    }
  }

  private getProjectPayees() {
    this.payeesLoading = true;
    getProjectPayees(this.mapSearchParams(this.pagination, this.searchTerms, this.sortMappings), this.projectId, this.searchCriteria, this.aggSearchCriteria, this.payeeSearchCriteria).then((response) => {
      this.payees = response;
      // On the first unfiltered response set the facets.
      if (this.bInitialLoad) {
        setPayeeSearchFacets(this.searchCriteria, this.payees, [ this.helper.defaultExpenseClasses as ReferenceData ], this.helper.diversityReferenceData);
      }
      this.bInitialLoad = false;
    }).catch((error) => {
      this.arrErrors.push(error);
    }).finally(() => {
      this.payeesLoading = false;
    });
  }

  private navProjectPayee(id: string) {
    this.$emit('navigate', id);
  }

  @Watch('helper.isLoading')
  private onHelperChanged(val: any, oldVal: any) {
    this.helperLoading = this.helper.isLoading;
  }

  @Watch('projectHelper.isLoading')
  private onProjectHelperChanged(val: any, oldVal: any) {
    this.projectHelperLoading = this.projectHelper.isLoading;
  }

  @Watch('pagination', { deep: true })
  private onPaginationChanged(val: any, oldVal: any) {
    if (!_.isEqual(val, oldVal) && !this.bInitialLoad) {
      if (!this.bInitialLoadWatch) {
        this.getProjectPayees();
      }
    }
    this.bInitialLoad = false;
    this.bInitialLoadWatch = false;
  }
}
